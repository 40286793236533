import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Common } from 'app/model/entity/common';
import { Ticket } from 'app/model/entity/ticket';
import { ReservationDetail } from 'app/model/entity/ticket-manager/reservationDetail';
import { ReservationPrice } from 'app/model/entity/ticket-manager/reservationPrice';
import { StockDetail } from 'app/model/entity/ticket-manager/stockDetail';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import _ from 'lodash';
import moment from 'moment';
import { DialogConfirmReservationComponent } from '../dialog-confirm-reservation/dialog-confirm-reservation.component';

@Component({
  selector: 'dialog-add-reservation',
  templateUrl: './dialog-add-reservation.component.html',
  styleUrls: ['./dialog-add-reservation.component.scss']
})
export class DialogAddReservationComponent implements OnInit {
  languageKey: any;
  languageCode: any;
  ticketId: number;
  listTicket: Ticket[];
  listStock: StockDetail[];
  stockDetail: StockDetail = new StockDetail();
  listPriceClass: any[];
  listReservationTime: any[];
  listNumber: number[];
  formatDate: string;
  reservationDetail: ReservationDetail;
  reservationDetailOrigin: ReservationDetail;
  listDate: any[];
  priceClass: any;
  reservationTime: any;
  informationAccount: any;
  isDuplicate: boolean;
  listReservationPrice: ReservationPrice[];
  reservationPriceDetail: ReservationPrice;
  commonObject: Common;
  dialCodeLength: number;
  helper = Helper;
  listTripId: any[];
  isMulti: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogAddReservationComponent>,
    private dialogService: DialogService,
    private ticketEditorService: TicketEditorService,
    private ticketManagerService: TicketManagerService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    this.dialCodeLength == 0;
    if (data.languageKey) {
      this.languageKey = data.languageKey;
      this.languageCode = this.languageKey == 'en' ? 'en' : 'ja';
    }
    if (data.reservationDetail) {
      this.reservationDetail = _.cloneDeep(data.reservationDetail);
      this.reservationDetailOrigin = _.cloneDeep(data.reservationDetail);
      this.isDuplicate = true;
    } else {
      this.reservationDetail = new ReservationDetail();
      this.reservationDetail.reservationStatus = 'approved';
    }
    if (data.informationAccount) {
      this.informationAccount = data.informationAccount;
    }
    this.formatDate = 'yyyy-MM-dd';
    this.listNumber = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    this.commonObject = this.commonService.getCommonObject();
  }

  async ngOnInit(): Promise<void> {
    await this.getListTicket();
    if (this.isDuplicate) {
      this.ticketId = this.reservationDetail.ticketId;
      //Kiểm tra xem ticketId có thuộc listTicket không
      if (this.listTicket.some(e => e.ticketId == this.ticketId)) {
        //set listStock và listDate
        await this.getListDate(this.ticketId);

        //Set listPrice
        if (!this.listDate || !this.listDate.length) {
          return;
        }
        if (!this.reservationDetail.reserveOn) {
          return;
        }
        this.stockDetail.reserveOn = this.reservationDetail.reserveOn.substring(0, 10);
        await this.getListStock(this.stockDetail.reserveOn);

        //Set listReservationTime
        if (!this.listPriceClass || !this.listPriceClass.length) {
          return;
        }
        if (!this.reservationDetail.reservationClassId) {
          return;
        }
        if (this.listPriceClass.every(e => JSON.stringify(e) != JSON.stringify(this.reservationDetail.reservationClassId))) {
          return;
        }
        this.stockDetail.reservationClassId = this.reservationDetail.reservationClassId;
        this.setPriceClass(this.stockDetail.reservationClassId);

        //Set listTripId
        if (!this.listReservationTime || !this.listReservationTime.length) {
          return;
        }
        if (this.hasMilliseconds(this.reservationDetail.reserveOn)) {
          this.reservationTime = this.removeMilliseconds(_.cloneDeep(this.reservationDetail.reserveOn));
        } else {
          this.reservationTime = _.cloneDeep(this.reservationDetail.reserveOn);
        }
        this.setReservationTime(this.reservationTime);

        //Set stockDetail
        if (!this.listTripId || !this.listTripId.length) {
          return;
        }
        if (!this.reservationDetail.tripId) {
          return;
        }
        this.stockDetail.tripId = this.reservationDetail.tripId;
        this.setTripId(this.stockDetail.tripId);
      }
    }
  }

  /**
   * isValidateMinMax
   * @param input
   * @param min
   * @param max
   * @returns
   */
  isValidateMinMax(input: string, min: number, max: number): boolean {
    if (!input) {
      return false;
    }
    if (input.length < min || input.length > max) {
      return true;
    }
    return false;
  }

  /**
   * isNumberHalfSize
   * @param value
   * @returns
   */
  isValidateFormatPhoneNumber(value: string): boolean {
    const regex = /^[0-9]+$/;
    return !regex.test(value);
  }

  /**
   * isValidateFormatName
   * @param value
   * @returns
   */
  isValidateFormatName(value: string): boolean {
    const regex = /^[\u30A1-\u30FA\u0020\u3000\u0041-\u005A\u0061-\u007A\-.,']+$/;
    return !regex.test(value);
  }

  /**
   * isValidateFormatEmail
   * @param value
   * @returns
   */
  isValidateFormatEmail(value: string): boolean {
    const regex = /^[\w-+.!#$%&'*/=?^`{|}~]+@[\w-]+(\.[\w-]+)+$/;
    return !regex.test(value);
  }

  /**
   * validateInput
   * @param prop
   */
  validateInput(prop: string): void {
    switch (prop) {
      case 'representativeName':
        if (!this.reservationDetail.representativeName) {
          this.reservationDetail['isValidateRepresentativeName'] = true;
          this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
          this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
        } else {
          this.reservationDetail['isValidateRepresentativeName'] = false;
          if (this.isValidateFormatName(this.reservationDetail.representativeName)) {
            this.reservationDetail['isValidateRepresentativeNameFormat'] = true;
            this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
          } else if (this.isValidateMinMax(this.reservationDetail.representativeName, 1, 100)) {
            this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
            this.reservationDetail['isValidateRepresentativeNameMinMax'] = true;
          } else {
            this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
            this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
          }
        }
        break;
      case 'phoneNumber':
        if (!Helper.convertPhoneNumber(this.reservationDetail.phoneNumber)) {
          this.reservationDetail['isValidatePhoneNumber'] = true;
          this.reservationDetail['isValidatePhoneNumberFormat'] = false;
          this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
        } else {
          this.reservationDetail['isValidatePhoneNumber'] = false;
          if (this.isValidateFormatPhoneNumber(Helper.convertPhoneNumber(this.reservationDetail.phoneNumber))) {
            this.reservationDetail['isValidatePhoneNumberFormat'] = true;
            this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
          } else if (
            this.isValidateMinMax(Helper.convertPhoneNumber(this.reservationDetail.phoneNumber), 1, 20 - this.dialCodeLength - 1)
          ) {
            this.reservationDetail['isValidatePhoneNumberFormat'] = false;
            this.reservationDetail['isValidatePhoneNumberMinMax'] = true;
          } else {
            this.reservationDetail['isValidatePhoneNumberFormat'] = false;
            this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
          }
        }
        break;
      case 'email':
        if (!this.reservationDetail.email) {
          this.reservationDetail['isValidateEmailFormat'] = false;
          this.reservationDetail['isValidateEmailMinMax'] = false;
        } else {
          if (this.isValidateFormatEmail(this.reservationDetail.email)) {
            this.reservationDetail['isValidateEmailFormat'] = true;
            this.reservationDetail['isValidateEmailMinMax'] = false;
          } else if (this.isValidateMinMax(this.reservationDetail.email, 0, 254)) {
            this.reservationDetail['isValidateEmailFormat'] = false;
            this.reservationDetail['isValidateEmailMinMax'] = true;
          } else {
            this.reservationDetail['isValidateEmailFormat'] = false;
            this.reservationDetail['isValidateEmailMinMax'] = false;
          }
        }
        break;
      case 'remarkAnswer':
        if (!this.reservationDetail.remarkAnswer) {
          this.reservationDetail['isValidateRemarkAnswerMinMax'] = false;
        } else {
          if (this.isValidateMinMax(this.reservationDetail.remarkAnswer, 0, 200)) {
            this.reservationDetail['isValidateRemarkAnswerMinMax'] = true;
          } else {
            this.reservationDetail['isValidateRemarkAnswerMinMax'] = false;
          }
        }
        break;
      default:
        break;
    }
  }

  /**
   * validateBeforeSave
   */
  validateBeforeSave(): boolean {
    let flag = false;
    //representativeName
    if (!this.reservationDetail.representativeName) {
      this.reservationDetail['isValidateRepresentativeName'] = true;
      this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
      this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
      flag = true;
    } else {
      this.reservationDetail['isValidateRepresentativeName'] = false;
      if (this.isValidateFormatName(this.reservationDetail.representativeName)) {
        this.reservationDetail['isValidateRepresentativeNameFormat'] = true;
        this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
        flag = true;
      } else if (this.isValidateMinMax(this.reservationDetail.representativeName, 1, 100)) {
        this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
        this.reservationDetail['isValidateRepresentativeNameMinMax'] = true;
        flag = true;
      } else {
        this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
        this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
      }
    }

    //phoneNumber
    if (!Helper.convertPhoneNumber(this.reservationDetail.phoneNumber)) {
      this.reservationDetail['isValidatePhoneNumber'] = true;
      this.reservationDetail['isValidatePhoneNumberFormat'] = false;
      this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
      flag = true;
    } else {
      this.reservationDetail['isValidatePhoneNumber'] = false;
      if (this.isValidateFormatPhoneNumber(Helper.convertPhoneNumber(this.reservationDetail.phoneNumber))) {
        this.reservationDetail['isValidatePhoneNumberFormat'] = true;
        this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
        flag = true;
      } else if (this.isValidateMinMax(Helper.convertPhoneNumber(this.reservationDetail.phoneNumber), 1, 20 - this.dialCodeLength - 1)) {
        this.reservationDetail['isValidatePhoneNumberFormat'] = false;
        this.reservationDetail['isValidatePhoneNumberMinMax'] = true;
        flag = true;
      } else {
        this.reservationDetail['isValidatePhoneNumberFormat'] = false;
        this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
      }
    }

    //Email
    if (!this.reservationDetail.email) {
      this.reservationDetail['isValidateEmailFormat'] = false;
      this.reservationDetail['isValidateEmailMinMax'] = false;
    } else {
      if (this.isValidateFormatEmail(this.reservationDetail.email)) {
        this.reservationDetail['isValidateEmailFormat'] = true;
        this.reservationDetail['isValidateEmailMinMax'] = false;
        flag = true;
      } else if (this.isValidateMinMax(this.reservationDetail.email, 0, 254)) {
        this.reservationDetail['isValidateEmailFormat'] = false;
        this.reservationDetail['isValidateEmailMinMax'] = true;
        flag = true;
      } else {
        this.reservationDetail['isValidateEmailFormat'] = false;
        this.reservationDetail['isValidateEmailMinMax'] = false;
      }
    }

    //remarkAnswer
    if (!this.reservationDetail.remarkAnswer) {
      this.reservationDetail['isValidateRemarkAnswerMinMax'] = false;
    } else {
      if (this.isValidateMinMax(this.reservationDetail.remarkAnswer, 0, 200)) {
        this.reservationDetail['isValidateRemarkAnswerMinMax'] = true;
        flag = true;
      } else {
        this.reservationDetail['isValidateRemarkAnswerMinMax'] = false;
      }
    }

    return flag;
  }

  /**
   * confirm
   */
  confirm(): void {
    if (this.validateBeforeSave()) {
      return;
    }
    if (
      !this.stockDetail.stockId ||
      !this.ticketId ||
      !this.stockDetail.reserveOn ||
      !this.stockDetail.reservationClassId ||
      !this.reservationTime ||
      !this.reservationDetail.reservationStatus ||
      !this.reservationPriceDetail
    ) {
      return;
    }
    const reservationDetail = {
      stockId: this.stockDetail.stockId,
      representativeName: this.reservationDetail.representativeName,
      phoneNumber: this.reservationDetail.phoneNumber,
      email: this.reservationDetail.email,
      remarkAnswer: this.reservationDetail.remarkAnswer,
      reserveOn: this.reservationTime,
      name: this.stockDetail.name,
      reservationClassId: this.stockDetail.reservationClassId,
      reservationPriceDescription: this.stockDetail.reservationPriceDescription,
      reservationNumberAdult: this.reservationPriceDetail.priceAdult ? this.reservationDetail.reservationNumberAdult ?? 0 : undefined,
      priceLabelCustom1: this.reservationPriceDetail?.priceLabelCustom1,
      reservationNumberCustom1: this.reservationPriceDetail.priceCustom1 ? this.reservationDetail.reservationNumberCustom1 ?? 0 : undefined,
      priceLabelCustom2: this.reservationPriceDetail?.priceLabelCustom2,
      reservationNumberCustom2: this.reservationPriceDetail.priceCustom2 ? this.reservationDetail.reservationNumberCustom2 ?? 0 : undefined,
      priceLabelCustom3: this.reservationPriceDetail?.priceLabelCustom3,
      reservationNumberCustom3: this.reservationPriceDetail.priceCustom3 ? this.reservationDetail.reservationNumberCustom3 ?? 0 : undefined,
      totalPrice: this.getTotalPrice(),
      reservationStatus: this.reservationDetail.reservationStatus,
      isMulti: this.isMulti,
      originStopId: null,
      destStopId: null,
      originStopName: '',
      destStopName: ''
    };
    this.dialogService.showDialog(
      DialogConfirmReservationComponent,
      {
        data: {
          reservationDetail: reservationDetail,
          languageKey: this.languageKey
        }
      },
      result => {
        if (result) {
          this.dialogRef.close(result);
        }
      }
    );
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: string): string {
    if (!value) {
      return;
    }
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 21) {
      value = value.substring(0, 21) + '...';
    } else if (value.length > 36) {
      value = value.substring(0, 36) + '...';
    }
    return value;
  }

  /**
   * changeDisplayTime
   * @param value
   * @returns
   */
  changeDisplayTime(value: string): string {
    if (!value) {
      return;
    }
    return value.substring(11, 16);
  }

  /**
   * convertDate
   * @param date
   * @returns
   */
  convertDate(date: string): Date {
    if (date) {
      const year = parseInt(date.substring(0, 4));
      const month = parseInt(date.substring(5, 7)) - 1;
      const day = parseInt(date.substring(8, 10));
      const newDate = moment({ year, month, day }).toString();
      return new Date(newDate);
    }
  }

  /**
   * getListTicket
   */
  async getListTicket(): Promise<void> {
    this.listTicket = [];
    return new Promise<void>(resolve => {
      this.ticketEditorService.getTickets(this.informationAccount, null, null, true).subscribe(
        data => {
          this.listTicket = data;
          resolve();
        },
        error => {
          resolve();
        }
      );
    });
  }

  /**
   * getListDate
   * @param ticketId
   */
  async getListDate(ticketId: number): Promise<void> {
    this.listStock = [];
    this.listDate = [];
    this.stockDetail.reserveOn = null;
    this.listPriceClass = [];
    this.stockDetail.reservationClassId = null;
    this.listReservationTime = [];
    this.reservationTime = null;
    this.listTripId = [];
    this.stockDetail.tripId = null;
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    this.isMulti = false;
    //gán lại count
    this.setReservationNumber();

    //Chọn rỗng
    if (!ticketId) {
      return;
    }
    this.isMulti = this.listTicket.find(e => e.ticketId).sectionType == 'multi';
    const date = new Date(Helper.getCurrentDateByTimeZone(this.commonObject)).toISOString().split('T')[0];
    //Chọn ticket
    const payload = {
      ticketId: ticketId,
      startDate: date,
      endDate: ''
    };
    return new Promise<void>(resolve => {
      this.ticketManagerService.getStocksCalendar(payload).subscribe(data => {
        if (!data || !data.length) {
          resolve();
        }
        this.listDate = [...new Set(data.map(e => e.date).sort((a: string, b: string) => a.localeCompare(b)))];
        resolve();
      });
    });
  }

  /**
   * getListStock
   * @param event
   * @returns
   */
  getListStock(event: any): Promise<void> {
    this.listStock = [];
    this.listPriceClass = [];
    this.stockDetail.reservationClassId = null;
    this.listReservationTime = [];
    this.reservationTime = null;
    this.listTripId = [];
    this.stockDetail.tripId = null;
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    //gán lại count
    this.setReservationNumber();

    if (!event) {
      this.stockDetail.reserveOn = null;
      return;
    }
    this.stockDetail.reserveOn = event;
    const payload = {
      stockPartitionId: '',
      ticketId: this.ticketId,
      startDate: this.stockDetail.reserveOn,
      endDate: this.stockDetail.reserveOn
    };
    return new Promise<void>(resolve => {
      this.ticketManagerService.getStocks(payload).subscribe(data => {
        if (!data || !data.length) {
          resolve();
        }
        this.listStock = data;
        //Set listPriceClass
        if (this.stockDetail.reserveOn && this.listStock && this.listStock.length) {
          const listPriceClass = new Set(
            this.listStock
              .filter(e => e.reserveOn.substring(0, 10) == this.stockDetail.reserveOn)
              .map(e => e.reservationClassId)
              .sort((a, b) => a.localeCompare(b))
          );
          this.listPriceClass = [...listPriceClass];
        }
        resolve();
      });
    });
  }

  /**
   * setPriceClass
   * @param priceClass
   * @returns
   */
  setPriceClass(priceClass: any): void {
    this.listReservationTime = [];
    this.reservationTime = null;
    this.listTripId = [];
    this.stockDetail.tripId = null;
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    //gán lại count
    this.setReservationNumber();

    if (!priceClass) {
      return;
    }
    const listReservationTime = new Set(
      this.listStock
        .filter(
          e =>
            e.reserveOn.substring(0, 10) == this.stockDetail.reserveOn && JSON.stringify(e.reservationClassId) == JSON.stringify(priceClass)
        )
        .map(e => e.reserveOn)
        .sort((a, b) => a.localeCompare(b))
    );
    this.listReservationTime = [...listReservationTime];
  }

  /**
   * setReservationTime
   * @param reservationTime
   */
  setReservationTime(reservationTime: string): void {
    this.listReservationPrice = [];
    this.listTripId = [];
    this.stockDetail.tripId = null;
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    //gán lại count
    this.setReservationNumber();

    if (!reservationTime) {
      return;
    }
    this.listTripId = [
      ...new Set(
        this.listStock
          .filter(
            e =>
              JSON.stringify(e.reservationClassId) == JSON.stringify(this.stockDetail.reservationClassId) &&
              e.reserveOn == this.reservationTime
          )
          .map(e => e.tripId)
          .sort((a, b) => a.localeCompare(b))
      )
    ];
  }

  /**
   * setTripId
   * @param tripId
   * @returns
   */
  setTripId(tripId: string): void {
    this.listReservationPrice = [];
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    //gán lại count
    this.setReservationNumber();
    if (!tripId) {
      return;
    }
    const index = this.listStock.findIndex(
      e =>
        e.reserveOn == this.reservationTime &&
        JSON.stringify(e.reservationClassId) == JSON.stringify(this.stockDetail.reservationClassId) &&
        e.tripId == this.stockDetail.tripId
    );
    if (index == -1) {
      return;
    }
    this.stockDetail = _.cloneDeep(this.listStock[index]);
    this.stockDetail.reserveOn = this.stockDetail.reserveOn.substring(0, 10);
    const payload = {
      stockPartitionId: this.stockDetail.stockPartitionId,
      ticketId: this.ticketId,
      year: new Date(this.stockDetail.reserveOn).getFullYear(),
      month: new Date(this.stockDetail.reserveOn).getMonth() + 1
    };

    this.ticketManagerService.getReservationPrices(payload).subscribe(
      data => {
        this.listReservationPrice = data;
        if (this.listReservationPrice && this.listReservationPrice.length) {
          //Đang QA xem lấy bản ghi nào
          const indexReservationPrice = this.listReservationPrice.findIndex(
            e => e.reservationPriceId == this.stockDetail.reservationPriceId
          );
          if (indexReservationPrice != -1) {
            this.reservationPriceDetail = _.cloneDeep(this.listReservationPrice[indexReservationPrice]);
          }
        }
      },
      error => {}
    );
  }

  /**
   * getTotalPrice
   */
  getTotalPrice(): number {
    if (!this.reservationPriceDetail) {
      return 0;
    }
    return Helper.getTotalPrice(
      this.reservationPriceDetail.priceAdult,
      this.reservationDetail.reservationNumberAdult,
      this.reservationPriceDetail.priceCustom1,
      this.reservationDetail.reservationNumberCustom1,
      this.reservationPriceDetail.priceCustom2,
      this.reservationDetail.reservationNumberCustom2,
      this.reservationPriceDetail.priceCustom3,
      this.reservationDetail.reservationNumberCustom3
    );
  }

  /**
   * getStringTranslate
   * @param msg
   * @param param
   * @param param2
   * @returns
   */
  getStringTranslate(msg: string, param: string, param2?: string): string {
    const dataString = param2
      ? Helper.formatString(this.translateService.instant(`dialog-reservation.${msg}`), `${param}`, `${param2}`)
      : Helper.formatString(this.translateService.instant(`dialog-reservation.${msg}`), `${param}`);
    return dataString;
  }

  /**
   * setReservationNumber
   */
  setReservationNumber(): void {
    if (!this.isDuplicate) {
      this.reservationDetail.reservationNumberAdult = 0;
      this.reservationDetail.reservationNumberCustom1 = 0;
      this.reservationDetail.reservationNumberCustom2 = 0;
      this.reservationDetail.reservationNumberCustom3 = 0;
    } else {
      if (!this.reservationDetailOrigin) {
        return;
      }
      this.reservationDetail.reservationNumberAdult = _.cloneDeep(this.reservationDetailOrigin.reservationNumberAdult) ?? 0;
      this.reservationDetail.reservationNumberCustom1 = _.cloneDeep(this.reservationDetailOrigin.reservationNumberCustom1) ?? 0;
      this.reservationDetail.reservationNumberCustom2 = _.cloneDeep(this.reservationDetailOrigin.reservationNumberCustom2) ?? 0;
      this.reservationDetail.reservationNumberCustom3 = _.cloneDeep(this.reservationDetailOrigin.reservationNumberCustom3) ?? 0;
    }
  }

  /**
   * hasMilliseconds
   * @param dateString
   * @returns
   */
  hasMilliseconds(dateString: string): boolean {
    const regex = /\.\d{3}/;
    return regex.test(dateString);
  }

  /**
   * removeMilliseconds
   * @param dateString
   * @returns
   */
  removeMilliseconds(dateString: string): string {
    // Tách chuỗi thời gian thành các thành phần
    const [dateTimePart, timezonePart] = dateString.split('+');

    // Loại bỏ phần mili giây
    const dateTimeWithoutMs = dateTimePart.replace(/\.\d+/, '');

    // Ghép lại chuỗi thời gian mới
    return `${dateTimeWithoutMs}+${timezonePart}`;
  }

  /**
   * convertSpace
   */
  convertSpace(): void {
    if (!this.reservationDetail.representativeName) {
      return;
    }
    this.reservationDetail.representativeName = this.reservationDetail.representativeName.replace(/　/g, ' ');
  }
}
